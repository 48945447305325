import React from 'react'
//import {useIntl} from "gatsby-plugin-intl"
import Layout from '../components/layout'
import Header from '../components/header'
import InfoLine from '../components/infoline'



const AdditiveFertigung = (props) => (
  <Layout>
    
    <Header 
      title='Additive Fertigung'
      text=''
    />
    
    <div className='row'
    style={{
      margin: '0 auto',
         
    }}>
        <p>Für die industrielle Anwendung konstruieren und bauen wir hochwertige FDM-Drucker. Hochwertige Bauteile und eine Industriesteuerung liefern sehr präzise Drucke. Durch die eigene Konstruktion können wir die Drucker genau Ihren Anderungen gemäß planen und bauen</p>

    </div>

    <InfoLine
      title='Bauraumheizung'
    text='Durch eine integrierte Bauraumheizung können Materialien unter optimalen Bedingungen gedruckt werden.
    Die gewährleistet eine hohe Druckerzeugnisqualität.'
    />

    <InfoLine
      title='Bauraumgröße'
    text='Das Druckbett und die Druckhöhe können an die speziellen Anforderungen angepasst werden.'
    />

    <InfoLine
      title='Andere Materialien'
    text='Andere Materialien wie etwas Metall können mit hilfe einer Materialkatusche dem Druckerzeugnis hinzugefügt werden.'
    />
    </Layout>
)


export default AdditiveFertigung;

